<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form-group :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            :placeholder="$t('PLACEHOLDER.NAME')"
            trim
            @input="clearServerError('name')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.NAME')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.TOKEN')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-2"
            v-model="$v.form.token.$model"
            :state="validateState('token')"
            :placeholder="$t('FORM.TOKEN')"
            trim
            @input="clearServerError('token')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.token">{{ serverErrors.token[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.TOKEN')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.NOTIFY')}`" label-for="select-1" label-cols-md="2" label-align-md="right">
          <SelectInfinityScroll
            id="select-1"
            v-model="$v.form.notifyOrderStatuses.$model"
            :state="validateState('notifyOrderStatuses') === false"
            :items="statuses"
            :multiple="true"
            :placeholder="$t('BOT.STATUS_PLACEHOLDER')"
            @input="clearServerError('notifyOrderStatuses')"
          />
          <span class="form-text text-muted">{{ $t('BOT.STATUS_DESCRIPTION') }}</span>
          <b-form-invalid-feedback id="input-3-feedback">
            <template v-if="serverErrors.token">{{ serverErrors.notifyOrderStatuses[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.NOTIFY')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-right">
          <b-button :disabled="$loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
            <b-spinner v-if="$loading" small variant="light" />
            {{ $t('FORM.SUBMIT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/botRepository'
import SelectInfinityScroll from '@/components/forms-items/SelectInfinityScroll'
import { validationMixin } from 'vuelidate'
import serverVuelidate from '../../../mixins/serverVuelidate'
import InputForm from '../../forms-items/input'
import Entity from '../../../model/bot'

export default {
  name: 'BotForm',
  components: {
    InputForm,
    SelectInfinityScroll,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    entity: {
      type: [Entity, Object],
      default: () => new Entity(),
    },
  },
  data() {
    return {
      form: new Entity(),
      validations: {
        form: {
          name: { required },
          token: { required },
          notifyOrderStatuses: { required },
        },
      },
      statuses: [
        {
          uuid: 'in_progress',
          name: this.$t('BOT.IN_PROGRESS'),
        },
        {
          uuid: 'complete',
          name: this.$t('BOT.COMPLETE'),
        },
        {
          uuid: 'cancel',
          name: this.$t('BOT.CANCEL'),
        },
      ],
    }
  },
  watch: {
    entity() {
      Object.assign(this.form, this.entity)
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        token: '',
        notifyOrderStatuses: [],
      }
      this.$nextTick(this.$v.$reset)
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.loadingOn()
      this.clearServerErrors()

      const payload = {
        ...this.form,
        notifyOrderStatuses: this.form.notifyOrderStatuses.map((status) => status.uuid),
      }

      Repo.save(Object.assign(new Entity(), payload))
        .then(this.resetForm)
        .then(this.$toastSuccess('TOAST.SUCCESS'))
        .then(() => { this.$router.push({ name: 'companyBotIndex' }) })
        .catch((err) => {
          this.$toastDanger('TOAST.ERROR')
          this.seServerErrors(err.response.data)
        })
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>

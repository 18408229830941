import Entity from './entity'

export default class Bot extends Entity {
  /** @type String */
  uuid
  /** @type String */
  name
  /** @type String */
  token
  /** @type Array */
  notifyOrderStatuses

  persistentProps() {
    return {
      name: this.name,
      token: this.token,
      notifyOrderStatuses: this.notifyOrderStatuses,
    }
  }
}

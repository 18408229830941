import EntityRepository from '@/core/repository/entityRepository'
import { typeOf } from '@/helpers/type'
import Bot from '@/model/bot'

export default new class BotRepository extends EntityRepository {
  baseUri() {
    return 'bot/telegram'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, Bot)) {
      ent = new Bot()
    }
    ent.uuid = props.uuid || ent.uuid
    ent.name = props.name || ent.name
    ent.token = props.token || ent.token
    ent.notifyOrderStatuses = props.notifyOrderStatuses || ent.notifyOrderStatuses

    return ent
  }

  activate = async (uuid) => this.httpClient().post(`${this.baseUri()}/${uuid}/activate`);

  deactivate = async (uuid) => this.httpClient().post(`${this.baseUri()}/${uuid}/deactivate`);
}()
